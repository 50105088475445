import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Hashtag } from "../../constants/hashtags.constants";
import { Sector } from "../../constants/sectors.constants";
import { ClientMetaInfo, ClientMetaInfoWithQueriedResources } from "../../types/app.types";
import { FiaFeaturedImageQuery } from "../../types/generated";

export const ClientMetaInfoFIA: ClientMetaInfo = {
  type: 'client',
  name: "fia",
  displayName: "FIA",
  abbreviation: "FIA",
  subtitle: "Fédération Internationale de l'Automobile",
  // TODO
  description: "",
  categories: [
    Category.design,
    Category.websites,
  ],
  sectors: [
    Sector.governmentAndNPO,
    Sector.motorsports,
    Sector.eLearning,
  ],
  projects: [
    "fia-rally-marshals-training",
    "idp",
  ],
  hasPage: true,
  hashtags: [
    Hashtag.digitalDesign,
    Hashtag.printDesign,
  ],
  keywords: [
    'print design',
    'digital design',
    'eLearning modules',
    'infographics',
  ],
}

export const useFIAInfo = () => {
  const FIAFeaturedImage: FiaFeaturedImageQuery = useStaticQuery(graphql`
    query FIAFeaturedImage {
      file(relativePath: {eq: "images/fia/fia-flag-navy-and-white.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
    }
  `)
  const info: ClientMetaInfoWithQueriedResources = {
    ...ClientMetaInfoFIA,
    featuredImage: {
      image: FIAFeaturedImage.file!.childImageSharp,
      alt: 'A blue FIA Official Flag waving',
      title: 'FIA Official Flag'
    },
  }
  return info
}
