import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Sector } from "../../constants/sectors.constants";
import { EntryMetaInfoWithQueriedResources, ImageWithMetaInfo, ProjectMetaInfo } from "../../types/app.types";
import { Turn2meImagesQuery } from "../../types/generated";

export const ProjectMetaInfoTurn2me: ProjectMetaInfo = {
  name: "turn2me",
  displayName: "turn2me.ie",
  abbreviation: "turn2me",
  subtitle: "Leading Irish Mental Health Online Service",
  description: "We assist turn2me.ie in delivering professional, inclusive and confidential counselling services to people in need in this unprecedented global crisis. As of April 30, 2021, a total of 22,811 new users joined turn2me.ie since the beginning of 2020, a significant surge for the mental health service, and the counselling hours has increased by 386%.",
  categories: [
    Category.apps,
    Category.websites,
  ],
  sectors: [
    Sector.healthAndFitness,
  ],
  url: "https://turn2me.ie/",
  hasPage: true,
  keywords: [
    'turn2me',
    'mental health platform',
    'app development',
    'web design',
    'HSE',
    'online counselling platform',
  ],
  theme: {
    backgroundColor: '#009FE3',
    foregroundColor: '#ffffff',
    backgroundImageOpacity: 1,
    primaryColor: '#ffffff',
  }
  // entryRenderer: Turn2meAppScreenshotSlider
}
export const useTurn2meInfo = () => {
  const Turn2meImages: Turn2meImagesQuery = useStaticQuery(graphql`
    query Turn2meImages {
      featuredImage: file(relativePath: {eq: "images/turn2me/turn2me-featured-image-speech-bubble-background.png"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/turn2me/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
      mobileScreenshots: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/turn2me/mobile-screenshots"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(
                width: 238
              )
            }
          }
        }
      }
    }
  `)
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: "turn2me Homepage Screenshot",
      alt: "turn2me Homepage Screenshot, desktop version",
    },
  ]
  const info: EntryMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoTurn2me,
    featuredImage: {
      image: Turn2meImages.featuredImage!.childImageSharp,
      title: 'turn2me Homepage',
      alt: 'turn2me Homepage Design'
    },
    gallery: Turn2meImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    })),
    // TODO: provide better title / alt values,
    mobileScreenshots: Turn2meImages.mobileScreenshots.edges.map((edge, i) => ({
      image: edge.node.childImageSharp,
      title: edge.node.name,
      alt: edge.node.name,
    }))
  }
  return info;
}