import * as React from "react"
import { AnimatedLink } from "../components/AnimatedLink/AnimatedLink"
import CTAButton from "../components/CTAButton/CTAButton"
import DecoAxonLines from "../components/DecoAxonLines/DecoAxonLines"
import FeaturedEntriesGrid from "../components/FeaturedEntriesGrid/FeaturedEntriesGrid"
import GridRow from "../components/GridRow/GridRow"
import HomepageTurn2meSection from "../components/HomepageTurn2meSection/HomepageTurn2meSection"
import Line from "../components/Line/Line"
import PageEndCTASection from "../components/PageEndCTASection/PageEndCTASection"
import PageSection from "../components/PageSection/PageSection"
import PageTemplate from "../components/PageTemplate/PageTemplate"
import Spacer from "../components/Spacer/Spacer"
import StandardTextBlock from "../components/StandardTextBlock/StandardTextBlock"
import TricolorMorphDef from "../components/TricolorMorphDef/TricolorMorphDef"
import TwoSplitTagline from "../components/TwoSplitTagline/TwoSplitTagline"
import Widowless from "../components/Widowless/Widowless"
import { useFIAInfo } from "../content/clients/fia.client"
import { useAAAIDPAnimationInfo } from "../content/projects/aaa-idp-animation.project"
import { useGCNInfo } from "../content/projects/gcn.project"
import { useGymCoachInfo } from "../content/projects/gymcoach.project"
import { useHopOnHopOffInfo } from "../content/projects/hoponhopoff.project"
import { useJimOCallaghanInfo } from "../content/projects/jim-ocallaghan.project"
import { useKLHInfo } from "../content/projects/klh.project"
import { useMajesticIrelandInfo } from "../content/projects/majestic-ireland.project"
import { useAppContext } from "../controllers/app.controller"
import { enableAxonFlow, enableInsights } from "../env"
import { useTextEnterSwoosh } from "../hooks/textEnterSwoosh.transition.hook"
import { useObservableRef } from "../hooks/useObservableRef.hook"
import { PageComponent } from "../types/gatsby.types"
import { isIE } from "../utils/browsers.utils"
import './index.scss'

const PageIndex: PageComponent = props => {

  // const { transitionStatus, entry, exit } = props;

  const fiaInfo = useFIAInfo();
  const majesticIrelandInfo = useMajesticIrelandInfo();
  const gcnInfo = useGCNInfo();
  const jimInfo = useJimOCallaghanInfo();
  const klhInfo = useKLHInfo();
  const idpAnimationInfo = useAAAIDPAnimationInfo();
  const hoponhopoffInfo = useHopOnHopOffInfo();
  const gymcoachInfo = useGymCoachInfo();

  const featuredEntries = [
    fiaInfo,
    majesticIrelandInfo,
    gcnInfo,
    hoponhopoffInfo,
    idpAnimationInfo,
    jimInfo,
    klhInfo,
    gymcoachInfo,
  ];

  return <PageTemplate
    {...props} // always include
    className="PageIndex"
    title="Home"
  >

    <DecoAxonLines id="IndexPageDecoAxonLines" enableParallaxEffect paths={{
      FG: 'm-141.5 160.86 267.78 134.88c2.912 1.467 6.125 2.235 9.386 2.245l71.36 0.207c4.837 0.014 9.531-1.643 13.288-4.69l105.04-85.183c8.62-6.99 21.19-6.067 28.696 2.108l210.91 229.7 40.125 56.18c8.343 11.681 25.686 11.735 34.102 0.106l241.92-334.29c9.603-13.27 30.026-10.926 36.372 4.175l164.05 390.37c3.75 8.909 13.09 14.112 22.64 12.607l111.94-17.651c1.85-0.292 3.73-0.335 5.6-0.126l209.69 23.404',
      R: 'm1369 713.26-249.35-198.55c-6.72-5.346-16.01-6.068-23.47-1.824l-153.64 87.409c-8.851 5.036-20.039 2.995-26.543-4.84l-159.54-192.22c-1.438-1.733-3.146-3.223-5.058-4.414l-199.84-124.44c-4.492-2.797-9.874-3.795-15.069-2.795l-90.336 17.385c-2.315 0.445-4.688 0.498-7.02 0.155l-135.74-19.938c-1.964-0.288-3.877-0.853-5.682-1.679l-16.665-7.62c-6.155-2.815-10.576-8.424-11.875-15.066l-44.033-225.02c-2.885-14.746-19.871-21.778-32.336-13.388l-270.19 181.88c-13.352 8.988-31.437 0.231-32.666-15.818l-19.014-248.34',
      G: 'm1412 424h-308.64c-1.57 0-3.13 0.176-4.66 0.524l-410.71 93.555c-5.929 1.35-12.152 0.065-17.06-3.524l-162.34-118.71c-1.554-1.136-3.257-2.053-5.06-2.726l-111.03-41.387c-5.321-1.984-9.613-6.039-11.895-11.239l-11.229-25.591c-2.607-5.94-7.811-10.342-14.101-11.926l-162.73-40.986c-4.432-1.116-9.108-0.756-13.317 1.027l-29.176 12.353c-11.367 4.813-24.413-1.168-28.184-12.921l-33.382-104.03c-3.3638-10.483-14.252-16.588-24.95-13.991l-129.11 31.344c-4.5771 1.111-9.3948 0.651-13.679-1.306l-123.04-56.2',
      B: 'm1395.3 455.4-222.35 146.54c-9.04 5.958-21.13 4.067-27.91-4.365l-140.25-174.21-142.31-143.88c-7.247-7.327-18.74-8.312-27.128-2.326l-137.67 98.237c-2.578 1.839-5.537 3.071-8.658 3.605l-178.07 30.455c-5.737 0.981-11.625-0.463-16.257-3.989l-141.67-107.81c-6.191-4.712-14.48-5.615-21.54-2.346l-131.74 60.99c-10.419 4.823-22.779 0.386-27.751-9.962l-68.289-142.13c-2.7-5.618-7.7556-9.747-13.8-11.269l-104.81-26.399c-3.3134-0.834-6.7805-0.848-10.1-0.039l-171.39 41.76',
    }}/>

    <PageIndexHeroSection />

    <HomepageTurn2meSection />
    
    <PageSection id="HomepageFeaturedEntriesGridSection" className="HomepageFeaturedEntriesGridSection">
      <FeaturedEntriesGrid entries={featuredEntries} />
    </PageSection>

    <PageEndCTASection />
    
  </PageTemplate>
}

// ------------

const PageIndexHeroSection = () => {

  const { UI } = useAppContext();

  const headingTwoLineOneRef = useObservableRef<HTMLSpanElement>();
  const headingTwoLineTwoRef = useObservableRef<HTMLSpanElement>();

  useTextEnterSwoosh(headingTwoLineOneRef, { delay: 350, stagger: .008 });
  useTextEnterSwoosh(headingTwoLineTwoRef, { delay: 450, stagger: .008 });

  return <PageSection
    id="HomepageHeroSection"
    className="HomepageHeroSection"
    observeVisibility
  >

    <TricolorMorphDef 
      mobile={{
        R: 'M417 230.5C417 274.407 381.407 310 337.5 310C293.593 310 258 274.407 258 230.5C258 186.593 293.593 151 337.5 151C381.407 151 417 186.593 417 230.5Z',
        G: 'M101 417C101 467.258 60.2579 508 10 508C-40.2579 508 -81 467.258 -81 417C-81 366.742 -40.2579 326 10 326C60.2579 326 101 366.742 101 417Z',
        B: 'M404 628C404 664.451 374.451 694 338 694C301.549 694 272 664.451 272 628C272 591.549 301.549 562 338 562C374.451 562 404 591.549 404 628Z',
      }}
      desktop={{
        R: "M1048 195C1048 246.915 1005.91 289 954 289C902.085 289 860 246.915 860 195C860 143.085 902.085 101 954 101C1005.91 101 1048 143.085 1048 195Z",
        G: "M141 642C141 692.258 100.258 733 50 733C-0.257912 733 -41 692.258 -41 642C-41 591.742 -0.257912 551 50 551C100.258 551 141 591.742 141 642Z",
        B: "M1160 620C1160 656.451 1130.45 686 1094 686C1057.55 686 1028 656.451 1028 620C1028 583.549 1057.55 554 1094 554C1130.45 554 1160 583.549 1160 620Z",
      }}
    />

    <TwoSplitTagline
      start="Software"
      end="Supercharged."
    />

    <div>
      <h2 className="HomepageHeroSection__title">
        <span ref={headingTwoLineOneRef}>Full-Service </span>
        <span ref={headingTwoLineTwoRef}>Digital Agency</span>
        <Line />
      </h2>
      <Spacer />
      <div className="HomepageHeroSectionText">
        <StandardTextBlock className="HomepageHeroSectionText__paraA" animateTextEnter={{ delay: 500 }}>
          <p><strong>AxonDivision</strong> is a full-service digital agency that helps you succeed in this great acceleration of digitalisation with our unique blend of expertise in design, data and&nbsp;technology.</p>
        </StandardTextBlock>
        <StandardTextBlock className="HomepageHeroSectionText__paraB" animateTextEnter={{ delay: 600 }}>
          <Widowless>With our deepest care for you and your audience, we can help you realise your vision with carefully crafted strategies and supercharged digital experiences.</Widowless>
        </StandardTextBlock>
        { !UI.cssFeatures.grid && !isIE && <div /> }
        <div className="HomepageHeroSectionText__contact">
          <CTAButton
            title="Contact Us"
            to="/contact"
            drawLine="right"
            ribbons
            gaEvent={{ event: "contact-button--hero" }}
            // animationDelay={1.5}
          >Contact Us</CTAButton>
        </div>
      </div>
      <Spacer />
      <GridRow className="HomepageHeroFeaturedLinkList">
        <div>
          <Line />
          <AnimatedLink to="/apps" title="Apps">
            <span>View</span>
            <strong>Apps</strong>
          </AnimatedLink>
        </div>
        <div>
          <Line />
          <AnimatedLink to="/websites" title="Websites">
            <span>View</span>
            <strong>Websites</strong>
          </AnimatedLink>
        </div>
        <div>
          <Line />
          <AnimatedLink to="/design" title="Design">
            <span>Know more about</span>
            <strong>Design</strong>
          </AnimatedLink>
        </div>
        
        {!isIE && <>
          <div className="u-lg">
            <Line />
            {enableInsights && <AnimatedLink to="/insights" title="Insights">
              <span>News & Blog</span>
              <strong>Insights</strong>
            </AnimatedLink>}
          </div>
          <div className="last">
            <Line />
            {enableAxonFlow && <AnimatedLink to="/flow" title="AXON Flow">
              <span>Our in-house project mngt app</span>
              <strong>AXON Flow</strong>
            </AnimatedLink>}
          </div>
        </>}
      </GridRow>
    </div>
  </PageSection>
  
}

// ------------

export default PageIndex
