import { Observer } from 'mobx-react-lite';
import React from 'react';
import { ProjectMetaInfoTurn2me, useTurn2meInfo } from '../../content/projects/turn2me.project';
import { getEntryPagePath } from '../../utils/entry.utils';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import AppScreenshotSlider from '../AppScreenshotSlider/AppScreenshotSlider';
import EntryMetaInfoHeader from '../EntryMetaInfoHeader/EntryMetaInfoHeader';
import Widowless from '../Widowless/Widowless';
import './Turn2meAppScreenshotSlider.scss';

type Turn2meAppScreenshotSliderProps = {
  withHeader?: boolean,
  headerPosition?: 'top' | 'bottom',
}

const Turn2meAppScreenshotSlider: React.FC<Turn2meAppScreenshotSliderProps> = props => {
  
  const slides = useTurn2meInfo().mobileScreenshots!;

  return <Observer children={() => (
    <section className="Turn2meAppScreenshotSlider" data-header-position={props.headerPosition}>
      {props.withHeader && <header className="Turn2meAppScreenshotSliderHeader">
        <EntryMetaInfoHeader 
          className="Turn2meAppScreenshotSliderHeaderInner"
          entry={ProjectMetaInfoTurn2me}
        />
        <div className="Turn2meAppScreenshotSliderHeaderEnd">
          <Widowless>Supported By the HSE</Widowless>
          <svg width="64" height="49" viewBox="0 0 64 49"><use xlinkHref="#logo-hse" /></svg>
        </div>
      </header>}
      <AnimatedLink className="Turn2meAppScreenshotSliderWrapperLink" to={getEntryPagePath(ProjectMetaInfoTurn2me)} title={ProjectMetaInfoTurn2me.displayName}>
        <AppScreenshotSlider slides={slides} />
      </AnimatedLink >
    </section>
  )} />
}

export default Turn2meAppScreenshotSlider;