import { Power2, TweenLite } from 'gsap';
import React from 'react';
import { useAppContext } from '../../controllers/app.controller';
import { isBuildTime } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import { isIE } from '../../utils/browsers.utils';
import { makeDisposerController } from '../../utils/disposer.utils';
import { useStore } from '../../utils/mobx.utils';
import { observeVisibility } from '../../utils/visibilityObserver.util';
import Line from '../Line/Line';
import PageSection from "../PageSection/PageSection";
import StandardTextBlock from '../StandardTextBlock/StandardTextBlock';
import Turn2meAppScreenshotSlider from "../Turn2meAppScreenshotSlider/Turn2meAppScreenshotSlider";
import Widowless from '../Widowless/Widowless';
import './HomepageTurn2meSection.scss';

type HomepageTurn2meSectionProps = {}

// use of React.memo will prevent the component from rerendering unnecessarily,
// which could possibly override the animating number "382%".
 
const HomepageTurn2meSection: React.FC<HomepageTurn2meSectionProps> = React.memo(props => {

  const { UI } = useAppContext();
  const numberRef = useObservableRef();

  const s = useStore(() => ({
    value: 386,
  }))
  
  useOnMount(() => {
    if (isBuildTime) return;
    TweenLite.set(numberRef.current!, { opacity: 0 });
    let state = { value: 0 };
    const d = makeDisposerController();
    d.add(observeVisibility(numberRef, {
      id: 'HomepageTurn2meSectionContent__number',
      once: true,
      onBecomeVisible: () => {
        TweenLite.to(numberRef.current!, .2, { opacity: 1 });
        TweenLite.to(state, 2, {
          value: s.value,
          ease: Power2.easeInOut,
          onUpdate: () => {
            if (numberRef.current) numberRef.current.innerText = Math.floor(state.value) + '%';
          }
        })
      }
    }))
    return d.disposer;
  })

  // TODO: the numbers in this slide are all fake, needs to be updated
  
  return <PageSection id="HomepageTurn2meSection" className="HomepageTurn2meSection">
    <Line className="HomepageTurn2meSection__mobileTopLine" />
    <Turn2meAppScreenshotSlider withHeader />
    <div className="HomepageTurn2meSectionContent">
      {!isIE && <div
        id="HomepageTurn2meSectionContent__number"
        className="HomepageTurn2meSectionContent__number"
        ref={numberRef}
      >
        {s.value}%
      </div>}
      <h3 className="HomepageTurn2meSectionContent__title">
        {isIE && `${s.value}% `}increase in<br />
        turn2me counselling hours<br />
        since January 2020
      </h3>
      {!UI.cssFeatures.grid && <><div /><div /></>}
      <StandardTextBlock className="HomepageTurn2meSectionContent__paraA">
        <Widowless>As of April 30, 2021, a total of 22,811 new users joined turn2me.ie since the beginning of 2020, a significant surge for the mental health service as the COVID-19 pandemic affects the lives of millions around the world.</Widowless>
      </StandardTextBlock>
      <StandardTextBlock className="HomepageTurn2meSectionContent__paraB">
        <Widowless>We assist turn2me.ie in delivering professional, inclusive and confidential counselling services to people in need in this unprecedented global crisis. Thousands of counselling sessions have been carried out, providing help to every corner on the island of Ireland.</Widowless>
      </StandardTextBlock>
    </div>
    <Line />
  </PageSection>
})

export default HomepageTurn2meSection;