import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useTextEnterSwoosh } from '../../hooks/textEnterSwoosh.transition.hook';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import joinClassName from '../../utils/className.utils';
import Line from '../Line/Line';
import './TwoSplitTagline.scss';

type TwoSplitTaglineProps = {
  className?: string,
  start: string,
  end: string,
  lineBreak?: 'auto' | 'always',
}

/**
 * Taglines that are split into two parts. If space is not enough to host the whole sentence, it automatically wraps into two lines.
 */
const TwoSplitTagline: React.FC<TwoSplitTaglineProps> = props => {

  const blockquoteLeftRef = useObservableRef<HTMLSpanElement>();
  const blockquoteRightRef = useObservableRef<HTMLSpanElement>();
  
  useTextEnterSwoosh(blockquoteLeftRef, { delay: 200, stagger: .008 });
  useTextEnterSwoosh(blockquoteRightRef, { delay: 400, stagger: .008 });
  
  return <Observer children={() => (
    <blockquote className={joinClassName('TwoSplitTagline', props.className)} data-line-break={props.lineBreak}>
      <p><span ref={blockquoteLeftRef}>{props.start}</span><Line /></p>
      <p><Line /><span ref={blockquoteRightRef}>{props.end}</span></p>
    </blockquote>
  )} />
}

export default TwoSplitTagline;