import { Observer } from 'mobx-react-lite';
import React from 'react';
import { EntryMetaInfo } from '../../types/app.types';
import joinClassName from '../../utils/className.utils';
import { getEntryPagePath } from '../../utils/entry.utils';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import Widowless from '../Widowless/Widowless';
import './EntryMetaInfoHeader.scss';

type EntryMetaInfoHeaderProps = {
  className?: string,
  entry: EntryMetaInfo,
  doNotAutoLink?: boolean,
}

const EntryMetaInfoHeader: React.FC<EntryMetaInfoHeaderProps> = props => {
  return <Observer children={() => (
    <div className={joinClassName('EntryMetaInfoHeader', props.className)}>
      <h2>
        {props.entry.hasPage && !props.doNotAutoLink ? <AnimatedLink
          to={getEntryPagePath(props.entry)}
          title={props.entry.displayName}
          children={props.entry.displayName}
        /> : props.entry.displayName}
      </h2>
      <Widowless>{props.entry.subtitle}</Widowless>
    </div>
  )} />
}

export default EntryMetaInfoHeader;