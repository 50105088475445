import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Hashtag } from "../../constants/hashtags.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { HopOnHopOffImagesQuery } from "../../types/generated";

export const ProjectMetaInfoHopOnHopOff: ProjectMetaInfo = {
  name: "hoponhopoff",
  displayName: "Hop On Hop Off Wine Tours",
  abbreviation: "HopOnHopOff",
  subtitle: "New Zealand Bus Tour Company",
  // TODO
  description: "New Zealand is a stunning country, and their wines deserve equal attention as the breathtaking nature. We designed the branding and website for the wine tour bus service, together with many peripheral designs such as the tour map and more. This is easily one of our favourite projects!",
  url: 'https://hoponhopoffwinetours.com/',
  categories: [
    Category.websites,
  ],
  sectors: [
    Sector.tourism,
  ],
  hasPage: true,
  hashtags: [
    Hashtag.branding,
    Hashtag.digitalDesign,
    Hashtag.printDesign,
  ],
  keywords: [
    'branding design',
    'web design',
    'print design',
    'WordPress',
    'New Zealand',
    'Bus Tours',
    'Wine Tours',
    'tourism',
  ],
}
export const useHopOnHopOffInfo = () => {
  const HopOnHopOffImages: HopOnHopOffImagesQuery = useStaticQuery(graphql`
    query HopOnHopOffImages {
      featuredImage: file(relativePath: {eq: "images/hoponhopoff/hoponhopoff-bus-parked-in-front-of-gibbston-valley-winery.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/hoponhopoff/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560)
            }
          }
        }
      }
      mobileScreenshots: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/hoponhopoff/mobile-screenshots"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(
                width: 238
              )
            }
          }
        }
      }
    }`
  )
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: "Hop On Hop Off: Homepage Screenshot",
      alt: "Hop On Hop Off: Homepage Screenshot, desktop version",
    },
    {
      title: "Hop On Hop Off: Office Exterior Wrappings Design",
      alt: "Hop On Hop Off: Office Exterior Wrappings Design",
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoHopOnHopOff,
    featuredImage: {
      image: HopOnHopOffImages.featuredImage!.childImageSharp,
      title: 'Hop On Hop Off Bus',
      alt: 'A black Hop On Hop Off wine tour bus outside of a winery restaurant',
    },
    gallery: HopOnHopOffImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    })),
    // TODO: provide better title / alt values,
    mobileScreenshots: HopOnHopOffImages.mobileScreenshots.edges.map((edge, i) => ({
      image: edge.node.childImageSharp,
      title: edge.node.name,
      alt: edge.node.name,
    }))
  }
  return info;
}