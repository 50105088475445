import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Sector } from "../../constants/sectors.constants";
import { EntryMetaInfoWithQueriedResources, ImageWithMetaInfo, ProjectMetaInfo } from "../../types/app.types";
import { GymCoachImagesQuery } from "../../types/generated";

export const ProjectMetaInfoGymCoach: ProjectMetaInfo = {
  name: "gymcoach",
  displayName: "GymCoach",
  abbreviation: "GymCoach",
  subtitle: "Health & Fitness App Design Concept",
  // TODO
  description: `GymCoach is an internal app concept that is designed to be "a personal trainer in your pocket", with smart suggestions of gym plans and easy-to-use in-session tracker UIs, and carefully designed helpful analytics screens to visualise your progress.`,
  categories: [
    Category.apps,
  ],
  sectors: [
    Sector.healthAndFitness,
  ],
  hasPage: true,
  keywords: [
    'app concept design',
    'gym',
    'fitness tracking app'
  ],
}
export const useGymCoachInfo = () => {
  const GymCoachImages: GymCoachImagesQuery = useStaticQuery(graphql`
    query GymCoachImages {
      featuredImage: file(relativePath: {eq: "images/gymcoach/gymcoach-project-background-two-persons-working-out-in-a-gym.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/gymcoach/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
      mobileScreenshots: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/gymcoach/mobile-screenshots"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(
                width: 238
              )
            }
          }
        }
      }
    }
  `)
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: 'GymCoach UI Designs',
      alt: 'GymCoach UI Designs',
    }
  ]
  const info: EntryMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoGymCoach,
    featuredImage: {
      image: GymCoachImages.featuredImage!.childImageSharp,
      title: 'GymCoach Featured Image',
      alt: 'GymCoach Project Image: Two persons working out in a gym'
    },
    gallery: GymCoachImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    })),
    // TODO: provide better title / alt values,
    mobileScreenshots: GymCoachImages.mobileScreenshots.edges.map((edge, i) => ({
      image: edge.node.childImageSharp,
      title: edge.node.name,
      alt: edge.node.name,
    }))
  }
  return info;
}