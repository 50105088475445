import { Observer } from 'mobx-react-lite';
import React from 'react';
import { EntryMetaInfoWithQueriedResources } from '../../types/app.types';
import './FeaturedEntriesGrid.scss';
import FeaturedEntry from './FeaturedEntry';

type FeaturedEntriesGridProps = {
  entries: EntryMetaInfoWithQueriedResources[]
}

const FeaturedEntriesGrid: React.FC<FeaturedEntriesGridProps> = props => {
  return <Observer children={() => (
    <ol className="FeaturedEntriesGrid">
      { props.entries.map((e, i) => <li
        key={e.name}
        className="FeaturedEntriesGridItem"
      >
        <FeaturedEntry entry={e} number={i + 1}/>
      </li>) }
    </ol>
  )} />
}

export default FeaturedEntriesGrid;