import { Observer } from 'mobx-react-lite';
import React from 'react';
import CTAButton from '../CTAButton/CTAButton';
import PageSection from '../PageSection/PageSection';
import './PageEndCTASection.scss';

type PageEndCTASectionProps = {
  preSelectTopic?: string,
}

const PageEndCTASection: React.FC<PageEndCTASectionProps> = props => {
  return <Observer children={() => (
    <PageSection className="PageEndCTASection" id="PageEndCTASection">
      <CTAButton
        title="Get in Touch"
        to={props.preSelectTopic ? `/contact?=${props.preSelectTopic}` : '/contact'}
        ribbons
        gaEvent={{ event: "contact-button--footer" }}
      >Get in Touch</CTAButton>
    </PageSection>
  )} />
}

export default PageEndCTASection;