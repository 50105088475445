import { Expo, TweenMax } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { when } from 'mobx';
import { usePageSectionContext } from '../components/PageSection/PageSection';
import { useAppContext } from '../controllers/app.controller';
import { isBrowser, isProduction } from "../env";
import { waitForFontsToLoad } from '../utils/fonts.utils';
import { highPerf } from '../utils/performance.utils';
import tick from '../utils/waiters.utils';
import { useOnMount } from "./lifecycle.hooks";
import { ObservableRef } from "./useObservableRef.hook";

export const useTextEnterSwoosh = (
  ref?: ObservableRef<HTMLParagraphElement | HTMLDivElement | HTMLHeadingElement | HTMLSpanElement>, 
  options?: {
    delay?: number,
    duration?: number,
    stagger?: number,
    from?: gsap.TweenVars,
    to?: gsap.TweenVars,
    onlyWhenParentSectionVisible?: boolean,
  }
) => {
  const pageSection = usePageSectionContext();
  const { UI } = useAppContext();
  useOnMount(() => {
    (async () => {
      if (isProduction && UI.isFirstLoad) return;
      if (!highPerf) return;
      if (!ref) return;
      if (isBrowser) {
        if (!ref.current) return;
        const spiltText = new SplitText(ref.current, { type: 'chars' });
        TweenMax.set(spiltText.chars, { opacity: 0 });
        await waitForFontsToLoad();
        if (options?.delay) await tick(options?.delay);
        if (options?.onlyWhenParentSectionVisible) await when(() => pageSection.visible);
        if (!ref.current) return;
        const styles = getComputedStyle(ref.current);
        // const color = styles.color;
        const XOffset = parseInt(styles.fontSize ?? 64) * .75;
        const duration = Math.max(options?.duration ?? XOffset / 256, .62);
        const stagger = options?.stagger ?? .019;
        TweenMax.fromTo(spiltText.chars!, duration, {
          opacity: 0,
          x: XOffset,
          // color: DefaultColorPalette.red,
          ...options?.from,
        }, {
          opacity: 1,
          x: 0,
          ease: Expo.easeOut,
          // color,
          stagger,
          // toggle the following line to view kerning differences before/after splitting text
          // onComplete: () => spiltText.revert(),
          ...options?.to,
        });
      }
    })();
  })
}