import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Hashtag } from "../../constants/hashtags.constants";
import { Sector } from "../../constants/sectors.constants";
import { ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { AaaidpAnimationImagesQuery } from "../../types/generated";

export const ProjectMetaInfoAAAIDPAnimation: ProjectMetaInfo = {
  name: "aaa-idp-animation",
  displayName: "IDP Introduction Video",
  abbreviation: "IDP Animation",
  subtitle: "Animation advertisement produced for AAA",
  // TODO
  description: "An animation we produced for Australian Automobile Association (AAA) to introduce the International Driving Permit.",
  categories: [
    Category.design,
  ],
  sectors: [
    Sector.governmentAndNPO,
  ],
  hashtags: [
    Hashtag.animations,
  ],
  hasPage: true,
  keywords: [
    'AAA',
    'Australian Automobile Association',
    'IDP',
    'International Driving Permit',
    'animation design',
    'advertisement design',
    'motion graphics',
  ],
}
export const useAAAIDPAnimationInfo = () => {
  const AAAIDPAnimationImages: AaaidpAnimationImagesQuery = useStaticQuery(graphql`
    query AAAIDPAnimationImages {
      featuredImage: file(relativePath: {eq: "images/aaa-idp-animation/aaa-idp-animation-frame-driving-towards-airport-to-travel-abroad.png"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      pageBackgroundImage: file(relativePath: {eq: "images/aaa-idp-animation/aaa-idp-animation-background-plane-landing-scene.png"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
    }
  `)
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoAAAIDPAnimation,
    featuredImage: {
      image: AAAIDPAnimationImages.featuredImage!.childImageSharp,
      title: 'IDP Introduction Video Preview',
      alt: 'IDP Introduction Video Preview Image showing two persons in a car driving towards the airport to travel abroad',
    },
    pageBackgroundImage: {
      image: AAAIDPAnimationImages.pageBackgroundImage!.childImageSharp,
      title: 'IDP Introduction Video Preview',
      alt: 'IDP Introduction Video Preview Image showing two persons in a car driving towards the airport to travel abroad',
    },
  }
  return info;
}