import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useFloatInOnVisible } from '../../hooks/useFloatInOnVisible.transition.hook';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import { EntryMetaInfoWithQueriedResources } from '../../types/app.types';
import { getEntryPagePath } from '../../utils/entry.utils';
import { padZero } from '../../utils/number.utils';
import { AnimatedLink } from '../AnimatedLink/AnimatedLink';
import EntryMetaInfoHeader from '../EntryMetaInfoHeader/EntryMetaInfoHeader';
import './FeaturedEntry.scss';

type FeaturedEntryProps = {
  entry: EntryMetaInfoWithQueriedResources,
  /** This number should be bigger than zero */
  number?: number,
}

const FeaturedEntry: React.FC<FeaturedEntryProps> = props => {
  const id = `FeaturedEntry--${props.entry.name}`;
  const image = getImage(props.entry.featuredImage?.image);
  const ref = useObservableRef();
  const animationRef = useObservableRef();
  useFloatInOnVisible(id, ref, animationRef);
  return <Observer children={() => {
    const imageEl = image ? <GatsbyImage
      image={image}
      title={props.entry.featuredImage?.title ?? props.entry.displayName ?? (props.entry.displayName + 'Featured Image')}
      alt={props.entry.featuredImage?.alt ?? (props.entry.displayName + 'Featured Image')}
      objectFit="cover"
      objectPosition="center"
    /> : null;
    const children = <>
      {props.number && <span className="FeaturedEntriesGridItemNumber">{padZero(props.number)}</span>}
      <EntryMetaInfoHeader className="FeaturedEntry__header" entry={props.entry} doNotAutoLink />
      {image && <figure className="FeaturedEntry__figure">
        {imageEl}
      </figure>}
    </>
    const commonAttr = {
      className: 'FeaturedEntryInner',
      children,
      ref: animationRef,
      'data-rect-offset': 5
    }
    return <div className="FeaturedEntry" id={id} ref={ref}>
      { props.entry.hasPage ? <AnimatedLink
        {...commonAttr}
        to={getEntryPagePath(props.entry)}
        title={props.entry.displayName}
      /> : <div {...commonAttr} />
      }
    </div>
  }} />
}

export default FeaturedEntry;