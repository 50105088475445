import { Linear, TimelineLite, TweenMax } from 'gsap';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useAppContext } from '../../controllers/app.controller';
import { isBuildTime } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import { isFirefox } from '../../utils/browsers.utils';
import { brighten, ColorPalette, darken } from '../../utils/colors.utils';
import { makeDisposerController } from '../../utils/disposer.utils';
import { useStore } from '../../utils/mobx.utils';
import { registerParallaxEffect } from '../../utils/parallax.utils';
import { highPerf } from '../../utils/performance.utils';
import './DecoAxonLines.scss';

type DecoAxonLinesProps = {
  id: string,
  enableParallaxEffect?: boolean,
  noStagger?: boolean,
  paths: { FG: string, R: string, G: string, B: string }
  opacity?: number
}

/**
 * The "electric" lines effect, usually used as a backdrop.
 */

const DecoAxonLines: React.FC<DecoAxonLinesProps> = props => {

  const { UI } = useAppContext();
  
  const fgWrapperRef = useObservableRef<SVGSVGElement>();
  const redWrapperRef = useObservableRef<SVGSVGElement>();
  const greenWrapperRef = useObservableRef<SVGSVGElement>();
  const blueWrapperRef = useObservableRef<SVGSVGElement>();
  const lineWhiteRef = useObservableRef<SVGPathElement>();
  const lineRedRef = useObservableRef<SVGPathElement>();
  const lineGreenRef = useObservableRef<SVGPathElement>();
  const lineBlueRef = useObservableRef<SVGPathElement>();
  const lineFGHighlightRef = useObservableRef<SVGPathElement>();
  const lineRedHighlightRef = useObservableRef<SVGPathElement>();
  const lineGreenHighlightRef = useObservableRef<SVGPathElement>();
  const lineBlueHighlightRef = useObservableRef<SVGPathElement>();

  const s = useStore(() => ({
    animateLines: async () =>{
      if (isBuildTime) return;
      const lines = [ lineWhiteRef.current!, lineRedRef.current!, lineGreenRef.current!, lineBlueRef.current! ];
      const lineHighlights = [ lineFGHighlightRef.current!, lineRedHighlightRef.current!, lineGreenHighlightRef.current!, lineBlueHighlightRef.current! ];
      if (highPerf) {
        TweenMax.set([...lineHighlights, ...lines], { drawSVG: "0%" })
        // await tick(300);
        TweenMax.to(lines, { drawSVG: '0% 100%', stagger: .15, ease: Linear.easeNone })
      } else {
        TweenMax.set(lineHighlights, { drawSVG: '0%' })
      }
      // await tick(300);
      const tl = new TimelineLite({ repeat: isFirefox ? 1 : -1 });
      tl.to(lineHighlights, { duration: .38, drawSVG: "95% 100%", stagger: props.noStagger ? undefined : .06, ease: Linear.easeNone }, 0)
      tl.fromTo(lines, { opacity: .7 }, { duration: 1, opacity: .5, ease: Linear.easeNone }, 0)
      tl.to(lineHighlights, { duration: .5, drawSVG: "0% 5%", stagger: .06, ease: Linear.easeNone }, .38)
      tl.fromTo(lines, { opacity: .9 }, { duration: 2, opacity: .5, ease: Linear.easeNone }, .38)
    },
  }))

  useOnMount(() => {
    if (isBuildTime) return;
    if (!props.enableParallaxEffect) {
      s.animateLines();
      return;
    }
    const d = makeDisposerController();
    d.add(registerParallaxEffect(fgWrapperRef, { id: `${props.id}__foregroundWrapperRef`, depth: -.5 }));
    d.add(registerParallaxEffect(greenWrapperRef, { id: `${props.id}__greenWrapperRef`, depth: -.45 }));
    d.add(registerParallaxEffect(redWrapperRef, { id: `${props.id}__redWrapperRef`, depth: -.4 }));
    d.add(registerParallaxEffect(blueWrapperRef, { id: `${props.id}__blueWrapperRef`, depth: -.35 }));
    s.animateLines();
    return d.disposer
  })

  return <Observer children={() => (
    isBuildTime ? null : <div className="DecoAxonLines" style={{ opacity: props.opacity }}>
      <svg viewBox="0 0 1194 834" fill="none" ref={fgWrapperRef}>
        <path d={props.paths.FG} ref={lineWhiteRef} stroke={UI.ui.isDarkTheme ? ColorPalette.white : ColorPalette.gray} strokeOpacity={.9} />
        <path d={props.paths.FG} ref={lineFGHighlightRef} stroke={(UI.ui.isDarkTheme ? brighten : darken)(UI.ui.isDarkTheme ? ColorPalette.white : ColorPalette.gray)} />
      </svg>
      <svg viewBox="0 0 1194 834" fill="none" ref={redWrapperRef}>
        <path d={props.paths.R} ref={lineRedRef} stroke={ColorPalette.red} />
        <path d={props.paths.R} ref={lineRedHighlightRef} stroke={(UI.ui.isDarkTheme ? brighten : darken)(ColorPalette.red, .5)} />
      </svg>
      <svg viewBox="0 0 1194 834" fill="none" ref={greenWrapperRef}>
        <path d={props.paths.G} ref={lineGreenRef} stroke={ColorPalette.green} />
        <path d={props.paths.G} ref={lineGreenHighlightRef} stroke={(UI.ui.isDarkTheme ? brighten : darken)(ColorPalette.green, .2)} />
      </svg>
      <svg viewBox="0 0 1194 834" fill="none" ref={blueWrapperRef}>
        <path d={props.paths.B} ref={lineBlueRef} stroke={ColorPalette.blue} />
        <path d={props.paths.B} ref={lineBlueHighlightRef} stroke={(UI.ui.isDarkTheme ? brighten : darken)(ColorPalette.blue, .5)} />
      </svg>
    </div>
  )} />
  
}

export default DecoAxonLines;

