import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { GcnImagesQuery } from "../../types/generated";

export const ProjectMetaInfoGCN: ProjectMetaInfo = {
  name: "gcn",
  displayName: "GCN",
  abbreviation: "GCN",
  subtitle: "Gay Community News Ireland",
  description: "As one of the major LGBT news magazine in Ireland, GCN celebrated their 30th birthday on 2017 with an exciting new brand identity. We are proud to have been entrusted with the complete redesign and development of their website, gcn.ie.",
  yearStart: 2017,
  categories: [
    Category.websites,
  ],
  sectors: [
    Sector.lgbtq,
    Sector.newsAndMedia,
  ],
  url: "https://gcn.ie/",
  hasPage: true,
  theme: {
    backgroundImageOpacity: .6
  },
  keywords: [
    'GCN',
    'web design',
    'web development',
    'WordPress',
    'Responsive web designs',
    'News website design',
    'Gay',
    'Lesbian',
    'LGBT',
    'LGBTQ+',
    'Ireland',
    'Gay Community News Ireland',
    'Irish Magazines Award 2017', 
  ],
}
export const useGCNInfo = () => {
  const GCNImages: GcnImagesQuery = useStaticQuery(graphql`
    query GCNImages {
      featuredImage: file(relativePath: {eq: "images/gcn/lgbtq-pride-people-waving-flag.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name}, 
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
          relativeDirectory: {eq: "images/gcn/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
      mobileScreenshots: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/gcn/mobile-screenshots"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(
                width: 238
              )
            }
          }
        }
      }
    }`
  )
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: 'GCN Homepage Screenshot',
      alt: 'GCN Homepage Screenshot, desktop version',
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoGCN,
    featuredImage: {
      image: GCNImages.featuredImage?.childImageSharp,
      alt: 'People Waving LGBTQ+ Flag',
      title: 'GCN Project Featured Image',
    },
    gallery: GCNImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    })),
    // TODO: provide better title / alt values,
    mobileScreenshots: GCNImages.mobileScreenshots.edges.map((edge, i) => ({
      image: edge.node.childImageSharp,
      title: edge.node.name,
      alt: edge.node.name,
    }))
  }
  return info;
}