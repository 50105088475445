import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { MajesticIrelandImagesQuery } from "../../types/generated";

export const ProjectMetaInfoMajesticIreland: ProjectMetaInfo = {
  name: "majestic-ireland",
  displayName: "Majestic Ireland",
  abbreviation: "Majestic Ireland",
  subtitle: "Irish Luxury Tourism Company",
  description: "We really enjoyed the cooperation with the team from Majestic Ireland in creating this beautiful website that shows off the most beautiful scenes throughout the island Ireland. Majestic Ireland Road Trips is an Irish tourism company that provides tailor-made self drive or chauffeur driven luxury vacations with an extensive range of super cars or high-end motoring options.",
  categories: [
    Category.websites,
  ],
  sectors: [
    Sector.tourism,
  ],
  url: "https://majesticirelandroadtrips.com/",
  hasPage: true,
  keywords: [
    'Majestic Ireland',
    'Road Trips',
    'tourism',
    'Irish Travel',
    'Ireland',
    'web design',
    'web development',
    'WordPress',
  ],
}
export const useMajesticIrelandInfo = () => {
  const MajesticIrelandImages: MajesticIrelandImagesQuery = useStaticQuery(graphql`
    query MajesticIrelandImages {
      featuredImage: file(relativePath: {eq: "images/majestic-ireland/majestic-ireland-skellig-michael.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
          relativeDirectory: {eq: "images/majestic-ireland/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
      mobileScreenshots: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/majestic-ireland/mobile-screenshots"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(
                width: 238
              )
            }
          }
        }
      }
    }
  `)
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: 'Majestic Ireland Homepage Screenshot',
      alt: 'Majestic Ireland Homepage Screenshot, desktop version',
    },
    {
      title: 'Majestic Ireland: Wishlist Feature',
      alt: 'Majestic Ireland: Wishlist Screen, desktop version',
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoMajesticIreland,
    featuredImage: {
      image: MajesticIrelandImages.featuredImage!.childImageSharp!,
      alt: 'Majestic Ireland Road Trips',
      title: 'Majestic Ireland: Skellig Michael under stormy clouds',
    },
    gallery: MajesticIrelandImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    })),
    // TODO: provide better title / alt values,
    mobileScreenshots: MajesticIrelandImages.mobileScreenshots.edges.map((edge, i) => ({
      image: edge.node.childImageSharp,
      title: edge.node.name,
      alt: edge.node.name,
    }))
  }
  return info;
}