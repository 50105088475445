import { Expo, TweenLite } from 'gsap';
import { isBuildTime } from "../env";
import { isIE } from '../utils/browsers.utils';
import { observeVisibility } from "../utils/visibilityObserver.util";
import { useOnMount } from "./lifecycle.hooks";
import { ObservableRef } from "./useObservableRef.hook";

export const useFloatInOnVisible = (
  id: string, 
  observedRef: ObservableRef, 
  animationTargetRef?: ObservableRef,
  delay?: number,
  duration?: number
) => {
  useOnMount(() => {
    if (isBuildTime) return;
    if (isIE) return;
    const getElement = () => (animationTargetRef ?? observedRef).current;
    TweenLite.set(getElement(), { opacity: 0 });
    observeVisibility(observedRef, {
      id,
      once: true,
      onBecomeVisible: () => {
        const element = getElement();
        if (!element) return;
        TweenLite.fromTo(element, duration ?? .5, {
          opacity: 0,
          y: 100,
        }, {
          delay: delay ?? (.19 * Math.random()),
          ease: Expo.easeOut,
          opacity: 1,
          y: 0,
        });
      },
    })
  })
}