import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Hashtag } from "../../constants/hashtags.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { JimOCallaghanImagesQuery } from "../../types/generated";

export const ProjectMetaInfoJimOCallaghan: ProjectMetaInfo = {
  name: "jim-ocallaghan",
  displayName: "Jim O'Callaghan",
  abbreviation: "Jim O'Callaghan",
  subtitle: "Leading Irish Politician (Fianna Fáil)",
  description: "A leading politician in Ireland, Jim O'Callaghan entrusted us to design a new personal branding and a website.",
  categories: [
    Category.websites,
  ],
  sectors: [
    Sector.governmentAndNPO,
  ],
  hashtags: [
    Hashtag.branding,
  ],
  url: "https://jimocallaghan.com/",
  hasPage: true,
  keywords: [
    "Jim O'Callaghan",
    'Fianna Fáil',
    'Ireland',
    'web design',
    'web development',
    'WordPress',
    'Responsive web designs',
    'branding'
  ],
}
export const useJimOCallaghanInfo = () => {
  const JimOCallaghanImages: JimOCallaghanImagesQuery = useStaticQuery(graphql`
    query JimOCallaghanImages {
      featuredImage: file(relativePath: {eq: "images/jim-ocallaghan/jim-ocallaghan-walking-among-group-of-young-people-alongside-canal-in-dublin-ireland.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/jim-ocallaghan/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
    }
  `);
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: "Jim O'Callaghan Homepage Screenshot",
      alt: "Jim O'Callaghan Homepage Screenshot, desktop version",
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoJimOCallaghan,
    featuredImage: {
      image: JimOCallaghanImages.featuredImage!.childImageSharp,
      title: "Jim O'Callaghan",
      alt: "Jim O'Callaghan walking among a group of young people alongside Dublin canal."
    },
    gallery: JimOCallaghanImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    }))
  }
  return info;
}