import React from 'react';
import joinClassName from '../../utils/className.utils';
import './GridRow.scss';

type GridRowProps = {
  className?: string,
}

const GridRow: React.FC<GridRowProps> = props => {
  return <div className={joinClassName('GridRow', props.className)}>
    {props.children}
  </div>
}

export default GridRow;