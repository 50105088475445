import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { KlhImagesQuery } from "../../types/generated";

export const ProjectMetaInfoKLH: ProjectMetaInfo = {
  name: "klh",
  displayName: "KLH Audio",
  abbreviation: "KLH",
  subtitle: "KLH Audio Speakers Website (Europe)",
  description: "Founded in 1957 in Cambridge, Massachusetts, KLH is a company of ground-breaking firsts and has created some of the bestselling speakers in the world.",
  categories: [
    Category.websites,
  ],
  sectors: [
    Sector.eCommerceAndRetail,
    Sector.musicAndAudio
  ],
  url: "https://www.klhaudio.eu/",
  hasPage: true,
  keywords: [
    'KLH',
    'KLH Audio',
    'web design',
    'web development',
    'Laravel',
    'eCommerce',
    'audio equipments',
  ],
}

export const useKLHInfo = () => {
  const KLHImages: KlhImagesQuery = useStaticQuery(graphql`
    query KLHImages {
      featuredImage: file(relativePath: {eq: "images/klh/klh-speakers-in-lounge.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/klh/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
      mobileScreenshots: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/klh/mobile-screenshots"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(
                width: 238
              )
            }
          }
        }
      }
    }
  `)
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: "KLH Audio EU Homepage Screenshot",
      alt: "KLH Audio EU Homepage Screenshot, desktop version",
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoKLH,
    featuredImage: {
      image: KLHImages.featuredImage!.childImageSharp,
      title: 'KLH Audio EU Website Homepage Desktop',
      alt: 'KLH Audio EU Website Homepage Desktop',
    },
    gallery: KLHImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    })),
    // TODO: provide better title / alt values,
    mobileScreenshots: KLHImages.mobileScreenshots.edges.map((edge, i) => ({
      image: edge.node.childImageSharp,
      title: edge.node.name,
      alt: edge.node.name,
    }))
  }
  return info;
}